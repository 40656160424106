import React from 'react'
import { useHistory } from 'react-router-dom'
import { getTrialDaysLeft } from '../../utils/utils'
import useCustomerStore from '../../stores/customerStore'
import useUserService from '../../api/useUserService'

export default function TrialInformation() {
  const history = useHistory()
  const { userHasRoleForResource } = useUserService()
  const trialData = useCustomerStore((state) => state.trialData)
  const customer = useCustomerStore((state) => state.customer)

  const getTrialScenariosLeft = () => {
    return 5 - trialData.numberOfScenariosUsed
  }

  const navigate = () => {
    if (userHasRoleForResource('FINANCIAL_MANAGER', trialData.customerIdentifier)) {
      history.push(`/customer/${trialData?.customerIdentifier}?activeTab=billing`)
    }
  }

  return (
    <>
      {customer && customer.licenseState === 'Trial' && trialData && (
        <span
          className={`navbar-item ${
            userHasRoleForResource('FINANCIAL_MANAGER', trialData.customerIdentifier)
              ? 'is-clickable'
              : ''
          }`}
          onClick={navigate}
        >
          <p className="subtitle mb-0">
            <span>{Math.max(0, getTrialDaysLeft(trialData))} days left in your trial, </span>
            {getTrialScenariosLeft() > 0 && (
              <span>{getTrialScenariosLeft()} free Knowledge Graph scenarios left to use</span>
            )}
            {!(getTrialScenariosLeft() > 0) && <span>no Knowledge Graph scenarios left.</span>}
          </p>
        </span>
      )}
    </>
  )
}
