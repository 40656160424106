import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BackgroundBalls from '../../components/BackgroundBalls'
import LoginWithMicrosoftButton from '../../components/LoginWithMicrosoftButton'
import Input from '../../components/Input'
import InputPassword from '../../components/InputPassword'
import { UserAuth } from '../../contexts/AuthContext'
import Icon from '../../components/Icon'
import Steps from '../../components/Steps'
import useUserService from '../../api/useUserService'
import useQuery from '../../utils/useQuery'
import ListItemChecked from '../../components/ListItemChecked'

export default function SignUpView() {
  const history = useHistory()
  const query = useQuery()

  const { isLoading, createUser, user, sendVerificationEmail } = UserAuth()
  const { isUserVerifiedOrTrusted } = useUserService()
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailVerificationSent, setEmailVerificationSent] = useState(false)

  useEffect(() => {
    if (user && isUserVerifiedOrTrusted()) {
      history.push({
        pathname: '/person/me',
        search: query.toString()
      })
    }
  }, [user, user?.emailVerified])

  const register = async () => {
    setError('')
    try {
      await createUser(
        email,
        password,
        `${window.location.origin}/configure-mfa?${query.toString()}`
      )
      setEmailVerificationSent(true)
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-email':
          setError('Email address is not valid')
          break
        case 'auth/weak-password':
          setError('Password should be at least 6 characters')
          break
        case 'auth/email-already-in-use':
          setError(
            'This email address is already in use, if you previously logged in with your Microsoft account, try "Sign in with Microsoft"'
          )
          break
        default:
          setError(e.message)
          break
      }
    }
  }

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      register()
    }
  }

  const handleSendVerificationEmail = async () => {
    await sendVerificationEmail(window.location.href)
    setEmailVerificationSent(true)
  }

  return (
    <>
      <BackgroundBalls />
      {!isLoading && (
        <div className="columns is-flex is-vcentered is-full-height">
          <div className="column">&nbsp;</div>
          <div className="column is-8-widescreen is-10-desktop is-11-mobile is-8-tablet">
            {!user && !isLoading && (
              <div className="columns is-vcentered is-full-height">
                <div className="column is-5 is-hidden-touch">
                  <div className="description-container">
                    <div className="login-ellipse">&nbsp;</div>
                    <p className="title is-4 has-text-primary-dark-blue">
                      Yes! We&apos;ve got a new look
                    </p>
                    <p className="subtitle is-5">
                      As of now we are called Gordium. Nice to see you here!
                    </p>
                    <img src="/name-change-gordium.png" alt="Gordium logo" className="mb-6" />
                  </div>
                  <p className="title is-4 mt-6">
                    Try-out the next-gen Process Hazard Analysis platform
                  </p>
                  <div className="content" style={{ marginBottom: '6rem' }}>
                    <ul
                      className="blockList mt-0"
                      style={{ listStyleType: 'none', marginLeft: 0, padding: 0 }}
                    >
                      <ListItemChecked value="Sign-up and get started in next 3 minutes" />
                      <ListItemChecked value="Full functional free trial, no billing data needed" />
                      <ListItemChecked value="Complete your first assessment in next 2 weeks" />
                    </ul>
                  </div>
                </div>
                <div className="column is-1 is-hidden-touch">&nbsp;</div>
                <div className="column is-6 is-12-touch">
                  <div className="is-hidden-desktop mt-4">
                    <img src="/name-change-gordium.png" alt="Gordium logo" className="mb-6" />
                  </div>
                  <div className="card">
                    <div className="card-content p-5">
                      {!user && !isLoading && (
                        <>
                          <div className="content">
                            <p className="title has-text-centered is-4">Sign-up</p>
                            <p className="mt-4 has-text-centered">
                              Is your company using Microsoft?
                            </p>
                            <p className="mt-4 has-text-centered">
                              <LoginWithMicrosoftButton signup={true} />
                            </p>
                            <div className="columns is-flex is-vcentered pl-3 pr-3">
                              <div className="column">
                                <hr />
                              </div>
                              <div className="column is-2 has-text-centered">or</div>
                              <div className="column">
                                <hr />
                              </div>
                            </div>
                          </div>

                          <div>
                            <Steps activeStep={1} />
                            {error && <p className="has-text-warning">{error}</p>}
                            <div className="field">
                              <label className="label">Email</label>
                              <div className="control">
                                <Input
                                  className="input"
                                  name="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label">Password</label>
                              <div className="control">
                                <InputPassword
                                  className="input"
                                  name="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  onKeyUp={handleOnKeyUp}
                                />
                              </div>
                            </div>
                            <div className="buttons is-right mt-4">
                              <button
                                type="button"
                                className="button is-ghost"
                                onClick={() => history.push('/')}
                              >
                                <span>Sign in</span>
                              </button>
                              <button
                                type="button"
                                className="button is-rounded"
                                onClick={register}
                              >
                                <span>Create account</span>
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {user && (
              <div className="card">
                <div className="card-content p-5">
                  <p className="title has-text-centered is-4">Sign-up</p>
                  {emailVerificationSent && (
                    <>
                      <Steps activeStep={2} />
                      <p>
                        Thank you for creating an account! A verification email has been sent to{' '}
                        {user?.email}. Please follow the instructions in the email to continue.
                      </p>
                    </>
                  )}
                  {!emailVerificationSent && (
                    <>
                      <Steps activeStep={2} />
                      <p>Hello user,</p>
                      <p className="mt-4">
                        your email address has not yet been verified. Please click the button below
                        to request a verification email. Follow the instruction in the email to
                        continue.
                      </p>
                      <div className="buttons is-right mt-4">
                        <button
                          type="button"
                          className="button is-rounded is-primary"
                          onClick={handleSendVerificationEmail}
                        >
                          <Icon icon="ic:outline-mail" />
                          <span>Verify email</span>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="column">&nbsp;</div>
        </div>
      )}
    </>
  )
}
